.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 3rem;
    background-color: rgba(242, 81, 38, 1);
    border-radius: 8px;
    border-color: transparent;
    cursor: pointer;
}

.button-container-small {
    width: 7rem;
    height: 2.5rem;
}

.button-text {
    font-weight: 500;
    font-size: 1.1em;
    color: white;
    font-family: 'Open Sans', sans-serif;
}
