#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.flex-container {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.base-container {
  margin-top: 4rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgba(52, 48, 74, 1);
}

html {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button { font-family: inherit }
input { font-family: inherit }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.PhoneInputInput {
  height: 2.5rem;
  width: 400px !important;
  border: 1px solid #3A3A61;
  border-radius: 6px;
  padding-left: 1em;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .PhoneInputInput {
    text-align: center;
    width: 100%;
    padding: 0;
  }
}
