.main-container {
    min-height: 100vh;
    padding: 2rem 15rem 4rem 15rem;
    display: flex;
    flex-direction: column;
}


@media screen and (max-width: 1400px) {
    .main-container {
        padding: 2rem 6rem 4rem 6rem;
    }
}

@media screen and (max-width: 768px) {
    .main-container {
        padding: 2rem 3rem;
    }
}
