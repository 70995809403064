.list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.title {
    margin-bottom: 2rem;
}

.item-flex {
    flex: 0 0 calc(33.33333% - 2rem);
}

@media screen and (max-width: 1024px) {
    .list-container {
        justify-content: center;
    }

    .item-flex {
        flex: 0 0 calc(50% - 2rem);
    }
}

@media screen and (max-width: 568px) {
    .item-flex {
        flex: 0 0 calc(100% - 2rem);
    }
}
