.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    text-align: center;
}

.video {
    max-width: 100%;
    width: 900px;
    height: 450px;
    border-radius: 6px;
}

.video-title {
    margin-bottom: 1rem;
}
