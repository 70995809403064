.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.logo {
    width: 200px;
    fill: rgba(52, 54, 71, 1);
}
