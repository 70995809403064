.menu-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    row-gap: 1rem;
}

.menu-container-footer {
    display: flex;
    align-items: center;
    gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
}

.link {
    white-space: break-spaces;
    text-align: center;
    color: rgba(52, 54, 71, 1);
    font-weight: bold;
    text-decoration: none;
}

.link-footer {
    flex: 1 0 calc(50% - 2rem);
    white-space: initial;
    text-align: left;
}

.hamburger {
    font-size: 2rem;
    display: none;
}

.navigation-menu {
    display: none;
}

@media screen and (max-width: 900px) {
    .menu-container-footer {
        display: none;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }

    .menu-container {
        display: none;
    }

    .navigation-menu ul {
        display: flex;
        position: absolute;
        top: 100px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 77px);
        background-color: white;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        z-index: 999;
    }

    .navigation-menu a {
        text-align: center;
        padding: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }

    .navigation-menu.expanded {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
    }
}
