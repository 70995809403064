.container {
    display: flex;
    flex-grow: 1;
    margin-top: 4rem;
    flex-direction: column;
}

.sessionExpiry {
    margin-top: 3rem;
    display: flex;
}

.header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 2rem;
}

.titleHeader {
    gap: 1rem;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .container {
        align-items: center;
        text-align: center;
    }

    .header {
        justify-content: center;
    }

    .titleHeader {
        flex-direction: column;
    }
}
