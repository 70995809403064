.panel-container {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 3rem 3rem 3rem;
}

.text-container {
    margin-top: 2rem;
    flex: 0 0 calc(50% - 2rem)
}

.title {
    margin-top: 1rem;
    margin-bottom: .5rem;
    margin-left: 0;
    background-color: #628DCD;
    color: aliceblue;
    text-align: center;
    padding: 1rem;
    border-radius: 6px;
}

.adresa {
    margin-top: 1rem;
    margin-bottom: .5rem;

}

.header {
    margin-top: 2rem;
    text-align: center;
    font-size: 2.5rem;
}

.image-container {
    margin-left: 0;
    margin-top: 5rem;
}

.image {
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

.round3 {
    border: 2px solid #628DCD;
    border-radius: 12px;
    margin-top: 0.2rem;
    padding: 5px;
}

@media screen and (max-width: 768px) {
    .panel-container {
        text-align: center;
        flex-wrap: wrap;
    }

    .text-container {
        order: 1 !important;
        margin-top: 2rem;
        flex: 1 1 100%;
    }
}
