.item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(58, 58, 97, 1);
    border-radius: 5px;
    padding: 3rem;
    cursor: pointer;
}

.title {
    margin-top: 1rem;
    font-weight: 500;
}

.icon {
    fill: rgba(58, 58, 97, 1);
}

.item-container:hover {
    border: 2px solid rgba(73, 142, 245, 1)
}

.item-container:hover .title {
    color: rgba(73, 142, 245, 1);
}

.item-container:hover .icon {
    fill: rgba(73, 142, 245, 1);
}
