.image {
    width: 75%;
    height: 75%;
    object-fit: contain;
}

.container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.86);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    animation: show 0.3s;
    animation-fill-mode: forwards;
}

.close-btn {
    cursor: pointer;
    color: white;
    font-size: 4rem;
    position: absolute;
    top: 0;
    right: 30px;
}

@keyframes show {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}
