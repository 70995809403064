.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right bottom, #845ec2, #7466c8, #626ecc, #4e74cf, #377ad0, #247fce, #1183ca, #0087c6, #008abd, #008cb3, #008ea7, #008e9b);    min-height: 100vh;
    padding: 4rem 2rem;
}

.header {
    text-align: center;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
}

.contact-email {
    color: white;
    margin-bottom: 1rem;
}

.app-title {
    color: white;
    margin: .5rem 0 0 0;
    font-weight: 400;
    font-size: 1.9rem
}

.first-description {
    color: white;
    margin-bottom: 2rem;
    font-size: 1.3rem;
}

.contact-form {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-form label {
    margin-bottom: .3rem;
    color: white;
}

.contact-form input {
    margin-bottom: 1.5rem;
    border: 1px solid #3A3A61;
    height: 2.5rem;
    width: 100%;
    border-radius: 6px;
    padding-left: 1em;
}

.contact-form textarea {
    border: 1px solid #3A3A61;
    height: 200px;
    width: 100%;
    border-radius: 6px;
    padding: 1em;
}

.submit-btn {
    margin-top: 1rem;
    width: 200px !important;
    align-self: center;
    background-color: rgb(73, 142, 245);;
    color: white;
    border-width: 0 !important;
}

.contact-physically {
    max-width: 400px;
    color: white;
    margin-bottom: 3rem;
    text-align: center;
    white-space: break-spaces;
}
