.codeInput {
    height: 2.5rem;
    width: 400px;
    border: 1px solid #3A3A61;
    border-radius: 6px;
    padding-left: 1em;
}

.label {
    font-weight: 500;
    margin-bottom: .2rem
}

.form {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.form-container {
    margin: 2rem 0 1.5rem 0;
}

@media screen and (max-width: 768px) {
    .codeInput {
        text-align: center;
        width: 100%;
        padding: 0;
    }

    .form {
        align-items: center;
        width: 100%;
    }

    .form-container {
        width: 100%;
    }
}
