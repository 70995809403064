.successContainer {
    align-items: center;
    justify-content: center;
    text-align: center;
}

.successTitle {
    margin-top: .5rem;
    color: #49CF8F;
}

.successDescription {
    margin-top: .5rem;
}

.successIcon {
    width: 150px;
    height: 150px;
}

@media (max-width: 480px) {
    .successIcon {
        width: 100px;
        height: 100px;
    }
}
