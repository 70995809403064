.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #3A3A61;
    border-radius: 5px;
    height: 300px;
    cursor: pointer;
    text-align: center;
}

.documentIco {
    fill: #3A3A61;
    width: 50%;
}

.title {
    margin-top: 1.5rem;
}

.container:hover {
    border: 2px solid rgba(73, 142, 245, 1)
}

.container:hover .title {
    color: rgba(73, 142, 245, 1);
}

.container:hover .documentIco {
    fill: rgba(73, 142, 245, 1);
}
