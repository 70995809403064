.select-language {
    border: 0;
    background-color: transparent;
}

@media screen and (max-width: 900px) {
    .select-language {
        align-self: center;
        margin: 1rem;
    }
}
