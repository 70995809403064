.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right bottom, #845ec2, #7466c8, #626ecc, #4e74cf, #377ad0, #247fce, #1183ca, #0087c6, #008abd, #008cb3, #008ea7, #008e9b);    min-height: 100vh;
    padding: 4rem 2rem;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
}

.app-title {
    color: white;
    margin: .5rem 0 1rem 0;
    font-weight: 400;
    font-size: 1.9rem
}

.first-description {
    color: white;
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.second-description {
    color: white;
    font-weight: 400;
    margin-bottom: 3rem
}
