.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding-top: 3rem;
}

.logo {
    width: 200px;
}

@media screen and (max-width: 768px) {
    .footer-container {
        justify-content: center;

    }
}
