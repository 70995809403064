.logo {
    width: 200px;
    fill: #ffffff;
}

.full-screen {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-image: radial-gradient(circle, rgba(16,16,48,0) 0%, rgba(16,16,48,0.2) 20%, rgba(20,19,52,0.5) 65%)  , url('../../../assets/images/png/Artboard 2 1.png');
}

.content {
    min-height: 100vh;
    padding: 2rem 10rem 4rem 10rem;
    display: flex;
    flex-direction: column;
}

.header {
    flex-grow: 1;
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    max-width: 50%;
    color: white;
}

.description {
    font-weight: 300;
    max-width: 700px;
    color: white;
}

.description b{
    color: white;
}

.footer {
    display: flex;
    align-items: flex-end;
}

.landing-pg-btn {
    border: 2px solid white !important;
    width: 11rem !important;
    height: 3.5rem !important;
    font-weight: 600;
}

.landing-pg-btn-txt {
    color: white;
    font-size: 1.2em;
    font-family: 'Open Sans', sans-serif;
    margin-right: .3em;
}

.text-container {
    flex-grow: 1
}

@media(max-width: 768px) {
    .content {
        padding: 2rem 6rem 4rem 6rem;
    }
    .footer {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
    .description {
        margin-bottom: 1rem;
        flex-grow: 1;
    }
    .text-container {
        flex-grow: 1;
    }
    .header {
        flex-grow: 1;
    }
    .title {
        max-width: initial;
    }
}

@media(max-width: 481px) {
    .content {
        padding: 2rem 3rem 4rem 3rem;
    }
}

