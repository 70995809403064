.home {
    height: 100%;
}

.section1 {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    justify-content: space-between;
}

.section1-branding-container {
    flex: 1;
}

.section1-store-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.section1-store-qr {
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}



.title {
    font-size: 3rem;
    color: rgba(52, 54, 71, 1);
    font-weight: 800;
}

.description {
    margin-top: 1rem;
    color: rgba(52, 54, 71, 1);
    font-size: 1.2rem;
}

.branding-store-button {
    width: 180px;
    height: 53px;
}

.section-1-txt-container {
    margin-bottom: 4rem;
}

.section2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7rem 0 7rem 0;
    gap: 2rem;
}

.section2-title {
    color: rgba(52, 54, 71, 1);
    font-size: 2rem;
}

.section2-txt-container {
}

.vehicle-b-btn {
    width: 11rem !important;
    height: 3.5rem !important;
    border-radius: 4px;
}

.vehicle-b-btn-txt {
    color: white;
    font-weight: 500;
    white-space: break-spaces;
    margin-right: 1.5em;
    text-align: left;
}

.section3 {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    margin: 7rem 0 7rem 0;
}

.section3-txt-container {
    flex: 1 1 50%;
}

.section3-description {
    margin: .7rem 0 3rem 0;
}

.view-accidents-btn {
    width: 13rem !important;
}

.section4, .section5 {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 7rem 0 7rem 0;
}

.section4-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10rem;
    row-gap: 5rem;
}

.section4-title {
    margin-bottom: 4rem;
    font-size: 2.5rem;
}

.partners-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 6rem;
    row-gap: 3rem;
}

.section5-margin {
    margin: 7rem 0 10rem 0;
}

.image {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    flex: 1;
    object-fit: contain;
}

.section1-branding-image {
    flex: 1;
    display: flex;
}

.qr-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: #5cbaef;
    padding: 1rem;
    border-radius: 6px;
    max-width: fit-content;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .qr-container-wrapper {
        display: flex;
        justify-content: center;
    }
    .section1-branding-image {
        display: none;
    }
    .section1-branding-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .section1-store-buttons {
        justify-content: center;
    }
    .section2 {
        justify-content: center;
        flex-wrap: wrap;
    }
    .home {
        text-align: center;
    }
    .section3 {
        flex-wrap: wrap;
        justify-content: center;
    }
    .section3-txt-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 700px) {
    .qr-container {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 450px) {
    .title {
        font-size: 2.5rem;
    }
}
