.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 0 0 calc(33.333333% - 10rem);
}

.icon-container {
    padding: .5em;
    border-radius: 10px;
    border: 3px solid rgba(98, 141, 205, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    margin-top: 1rem;
}

.description {
    margin-top: .2rem;
}

.icon-style {
    width: 55px;
    height: 55px;
}

@media screen and (max-width: 768px) {
    .container {
        flex: 0 0 calc(100% - 10rem);
    }
}
