.panel-container {
    display: flex;
    margin: 3rem 0 3rem 0;
    gap: 2rem;
    align-items: center;
}

.text-container {
    margin-top: 2rem;
    flex: 0 0 calc(50% - 2rem)
}

.title {
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.image-container {
    flex: 0 0 calc(50% - 2rem);
}

.image {
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .panel-container {
        text-align: center;
        flex-wrap: wrap;
    }

    .text-container {
        order: 1 !important;
        margin-top: 2rem;
        flex: 1 1 100%;
    }

    .image-container {
        flex: 1 1 100%;
        order: 0 !important;
    }
}
